import React from 'react';
import SanityImage from '../SanityImage';
import styled from 'styled-components';

const Container = styled.div`
  .gatsby-image-wrapper,
  img {
    max-height: 100vh;
    width: 100%;
  }
`;

const Hero = ({ input }) => {
  return (
    <Container className="hero ">
      {input && <SanityImage image={input} objectFit="cover" alt="" />}
    </Container>
  );
};

export default Hero;
