import React from 'react';
import clsx from 'clsx';
import HeadlineUI from './HeadlineUI';
import TexteUI from './TexteUI';
// import TextesUI from './TextesUI';
import ImageUI from './ImageUI';
import ImagesUI from './ImagesUI';
// import QuoteUI from './QuoteUI';
// import TrombinoscopeUI from './TrombinoscopeUI';
import SliderUI from './SliderUI';
import SliderDyptique from './SliderDyptique';
import SliderMosaic from './SliderMosaic';
import VideoUI from './VideoUI';
import AnimateOnScrollFramer from '../ui/AnimateOnScrollFramer';
import './index.scss';
// import Spacer from './Spacer';

const ModulesProjet = ({ input }) => {
  const _renderModules = () => {
    const _modules = input.map((module, i) => {
      // console.log(module);
      if (!module) return null;
      switch (module._type) {
        case 'headlineUI':
          return (
            <AnimateOnScrollFramer key={module._key}>
              <HeadlineUI key={module._key} input={module} />
            </AnimateOnScrollFramer>
          );
        case 'imageUI':
          return (
            <AnimateOnScrollFramer key={module._key}>
              <ImageUI key={module._key} input={module} />
            </AnimateOnScrollFramer>
          );
        case 'imagesUI':
          return (
            <AnimateOnScrollFramer key={module._key}>
              <ImagesUI key={module._key} input={module} />
            </AnimateOnScrollFramer>
          );
        case 'texteUI':
          return (
            <AnimateOnScrollFramer key={module._key}>
              <TexteUI key={module._key} input={module} />
            </AnimateOnScrollFramer>
          );

        case 'sliderUI':
          return <SliderUI key={module._key} input={module} showTitle={true} />;
        case 'sliderDyptiqueUI':
          return <SliderDyptique key={module._key} input={module} />;
        case 'sliderMosaicUI':
          return <SliderMosaic key={module._key} input={module} />;

        case 'videoUI':
          return <VideoUI key={module._key} input={module} />;
        default:
          return null;
      }
    });
    return _modules;
  };

  return <div className={clsx(`modules overflow-x-hidden- w-full`)}>{_renderModules()}</div>;
};

export default ModulesProjet;
