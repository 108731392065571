import React from 'react';
import VideoPlayer from '../ui/VideoPlayer';

const VideoUI = ({ input }) => {
  console.log(input);
  return (
    <section className="module module-video-ui ">
      {/* <code>{JSON.stringify(input, null, '\t')}</code> */}
      <div className="container aspect-video">
        <VideoPlayer url={input.url} />
      </div>
    </section>
  );
};

export default VideoUI;
