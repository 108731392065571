import clsx from 'clsx';
import { Link } from 'gatsby';
import React from 'react';
import { _linkResolver, _localizeField } from '../core/utils';
import useWrapperContext from './Layout';

const NavTagsItem = ({ input }) => {
  const { location } = useWrapperContext();
  const path = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);

  return (
    <div className="nav-tags--item mb-[3px]- pb-2 align-top">
      <Link
        to={_linkResolver(input)}
        className={clsx(
          'pill text-sm font-secondary bg-primary-5 hover:bg-primary-10 active:bg-primary active:text-secondary inline-block align-top has-backdrop-filter',
          path === input.slug.current ? 'is-active bg-primary text-secondary hidden' : ''
        )}
      >
        {_localizeField(input.title)}
      </Link>
    </div>
  );
};

export default NavTagsItem;
