import React from 'react';
import { graphql } from 'gatsby';
import Seo from '../components/seo';
import ModulesProjet from '../components/modules/ModulesProjet';
import Hero from '../components/ui/Hero';
import { _localizeField } from '../core/utils';
import NavTagsItem from '../components/NavTagsItem';
import SanityTexte from '../components/SanityTexte';

export const query = graphql`
  query ProjetBySlug($slug: String!) {
    sanityProjet(slug: { current: { eq: $slug } }) {
      id
      seo {
        ...seo
      }
      slug {
        current
      }
      title {
        ...localeString
      }
      imageHero: _rawImageHero(resolveReferences: { maxDepth: 1 })
      tags {
        _type
        slug {
          current
        }
        title {
          ...localeString
        }
      }
      chapo {
        ...localeBlockContent
      }
      ficheTechnique {
        clef {
          ...localeString
        }
        valeur {
          ...localeText
        }
      }
      modules {
        ... on SanityHeadlineUI {
          _key
          _type
          title
          hn
        }

        ... on SanityImageUI {
          _key
          _type
          image: _rawImage(resolveReferences: { maxDepth: 10 })
          caption {
            ...localeString
          }
          expandable
        }
        ... on SanityImagesUI {
          _key
          _type
          images {
            image: _rawImage(resolveReferences: { maxDepth: 10 })
            caption: _rawCaption(resolveReferences: { maxDepth: 10 })
            expandable
          }
        }
        ... on SanityTexteUI {
          _key
          _type
          texte: _rawTexte(resolveReferences: { maxDepth: 10 })
        }
        ... on SanitySliderUI {
          _key
          _type
          background
          title
          images {
            ... on SanityImageCard {
              _type
              image: _rawImage(resolveReferences: { maxDepth: 10 })
              caption: _rawCaption(resolveReferences: { maxDepth: 10 })
              expandable
            }
            ... on SanityProjet {
              _type
              ...projetCard
            }
          }
        }
        ... on SanitySliderDyptiqueUI {
          _key
          _type
          background
          title
          ctaProjects
          images {
            ... on SanityImageCard {
              _type
              image: _rawImage(resolveReferences: { maxDepth: 10 })
              caption: _rawCaption(resolveReferences: { maxDepth: 10 })
              title {
                ...localeString
              }
              expandable
            }
            ... on SanityProjet {
              _type
              ...projetCard
            }
          }
        }
        ... on SanitySliderMosaicUI {
          _key
          _type
          title
          slides {
            items {
              ... on SanityTexteCard {
                _type
                color {
                  hex
                }
                texte {
                  ...localeString
                }
              }
              ... on SanityImageCard {
                _type
                image: _rawImage(resolveReferences: { maxDepth: 10 })
                imageData: image {
                  asset {
                    gatsbyImageData(width: 800)
                    metadata {
                      palette {
                        dominant {
                          background
                        }
                      }
                    }
                  }
                }
                caption: _rawCaption(resolveReferences: { maxDepth: 10 })
                expandable
              }
            }
          }
        }
        ... on SanityVideoUI {
          _key
          _type
          url
        }
      }

      # related {
      #   _type
      #   ...projetCard
      # }
    }
  }
`;

const Projet = ({ data }) => {
  const { seo, slug, title, imageHero, tags, chapo, ficheTechnique, modules } = data.sanityProjet;
  // console.log(modules);
  return (
    <div className="main-area">
      {seo && (
        <Seo
          metaTitle={seo.metaTitle}
          metaDescription={seo.metaDescription}
          metaImage={seo.metaImage?.asset.url}
          template={`template-projet projet-${slug.current} `}
          page={true}
        />
      )}

      <div className="mb-_md md:mb-md">
        <Hero input={imageHero} />
      </div>
      <div className="container">
        <h1 className="text-_xl md:text-xl mb-1e md:mb-0">{_localizeField(title)}</h1>

        <div className="intro mb-_lg md:mb-lg">
          <div className="row">
            <div className="col-xs-12 col-md-8">
              <ul className="tags flex flex-wrap">
                {tags.map((tag, i) => (
                  <li key={i}>
                    <NavTagsItem input={tag} />
                  </li>
                ))}
              </ul>
              <div className="chapo mb-md md:mb-0 text-_lg md:text-lg">
                <SanityTexte input={chapo} />
              </div>
            </div>
            <div className="col-xs-12 col-md-4">
              <ul className="fiche-technique font-secondary ">
                {ficheTechnique.map((li, i) => (
                  <li key={i}>
                    <div className="clef text-_sm md:text-sm text-primary-50">
                      {_localizeField(li.clef)}
                    </div>
                    <p className="valeur text-_sm md:text-sm leading-1-2">
                      {_localizeField(li.valeur)}
                    </p>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>

      {modules && <ModulesProjet input={modules} />}

      {/* <section className="related bg-gray pt-xl pb-lg">
        <div className="container">
          <h2>{_localizeText('nextProjects')}</h2>

          <SliderDyptique input={related} />
        </div>
      </section> */}
    </div>
  );
};

export default Projet;
